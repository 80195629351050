import React, { Component } from 'react'
import ReactDOM from 'react-dom'

// util.
const canUseDOM = () =>
  !!(typeof window !== 'undefined' && window.document && window.document.createElement)

// id of <div> to act as Portal target
const MODAL_ROOT_ID = 'modal-root'


class Modal extends Component {
  static defaultProps = {
    disablePadding: false,
    isOpen: false,
    onAfterOpen: null,
    shouldOverlayClose: false,
    showCloseButton: true,
    smallMode: false,
  }

  // Create Portal target node and append to document.
  // Handy for test environments.
  static createModalRoot() {
    if (!canUseDOM()) {
      return null
    }
    const root = document.createElement('div')
    root.setAttribute('id', MODAL_ROOT_ID)
    if (document.body) {
      document.body.appendChild(root)
    }
    return root
  }

  componentDidMount() {
    const { isOpen } = this.props
    if (isOpen) {
      this.open()
    }
  }

  componentDidUpdate(prevProps) {
    const { isOpen } = this.props
    const justOpened = isOpen && !prevProps.isOpen
    if (justOpened) {
      this.open()
    }
  }

  open = () => {
    const { onAfterOpen } = this.props
    if (onAfterOpen) {
      onAfterOpen()
    }
  }

  createModal = props => {
    const {
      children,
      onClose,
    } = props
    return (
      <div class="modal" id="myModal" tabindex="-1" role="dialog">
        <div className='modal-dialog' role="document">
          <div className='overlay' onClick={onClose}></div>
          <div className="modal-content">
            <div className="modal-header">
              <button type="button" className="close" onClick={onClose} data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true">×</span>
              </button>
            </div>
            <div className="modal-body">{children}</div>  
          </div>
        </div>
      </div>

    )
  }
  //


  //

  render() {
    const { isOpen } = this.props

    // Don't render when DOM unavailable
    if (!canUseDOM()) {
      return null
    }

    // Query and assign Portal target node, create one if necessary
    const modalRoot = document.getElementById(MODAL_ROOT_ID)
      ? document.getElementById(MODAL_ROOT_ID)
      : Modal.createModalRoot()

    return isOpen && modalRoot !== null
      ? ReactDOM.createPortal(this.createModal(this.props), modalRoot)
      : null
  }
}

export default Modal
