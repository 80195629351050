
import React from "react"
import { useStaticQuery, graphql } from "gatsby"
const Contact = ({ children }) => {
  const data = useStaticQuery(graphql`
    query ContactText {
      allContentfulSite {
        nodes {
          email
          address
          phone
        }
      }
      allContentfulContact {
        nodes {
          title
          text {
            text
          }
        }
      }
    }
  `)
  const { title, text: { text }} = data.allContentfulContact.nodes[0]
  const { email, address, phone } = data.allContentfulSite.nodes[0]
  return (
    <div id="contact" class="section text-center landing-section section-green">
      <div class="container">
        <div class="row">
        <div class="col-md-10 col-md-offset-1">
            <h2 class="text-center">{title}</h2>
            <p>{text}</p>
            <ul class='contacts'>
              <li><div class='fa fa-mobile-phone'></div><a href={`tel:${phone}`}>{phone}</a></li>
              <li><div class='fa fa-envelope-o'></div><a href={`mailto:${email}`}>{email}</a></li>
              <li><div class='fa fa-book'></div><span>{address}</span></li>
            </ul>
          </div>
        </div>
          
      </div>
    </div>
  )
}

export default Contact
