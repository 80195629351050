import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

import Projects from '../components/projects'
import Professions from '../components/professions'
import About from '../components/about'
import Contact from '../components/contact'

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <About />
    <Professions />
    <Projects />   
    <Contact /> 
  </Layout>
)

export default IndexPage
