import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Professions = ({ children }) => {
  const data = useStaticQuery(graphql`
    query Professions {
      allContentfulProfessions {
        nodes {
          title
          professions {
            title
            description {
              json
            }
          }
        }
      }
    }
  `)
  const { title, professions } = data.allContentfulProfessions.nodes[0]
  return (
    <div id="services" class="section section-blue landing-section">
      <div class="container">
          <h2 class='professionHeding'>{title}</h2>
          <div class="row">
            {professions.map(({ title, description }) => (
              <div class="col-md-3 column">
                <h4>{title}</h4>
                <p>{documentToReactComponents(description.json)}</p>
              </div>
            ))}
          </div>
      </div>
    </div>
  )
}

export default Professions
