import React, { useState } from "react"
import { useStaticQuery, graphql } from "gatsby"
import Modal from "./modal"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"

const Projects = ({ children }) => {
  const data = useStaticQuery(graphql`
    query Projects {
      allContentfulProjects {
        nodes {
          title
          text {
            text
          }
          projects {
            title
            description {
              json
            }
            images {
              caption,
              image {
                file {
                  url
                }
              }
            }
          }
        }
      }
    }
  `)
  const [modalOpen, setModalOpen] = useState(false);
  const [modalContent, setModalContent] = useState(null);
  const closeHandler = () => {
    setModalOpen(false)
    setModalContent(null)
  }
  const openHandler = (content) => {
    setModalContent(content)
    setModalOpen(true)
  }
  const { title, text: { text }, projects } = data.allContentfulProjects.nodes[0]
  return (
    <div id="projects" class="section section-dark text-center landing-section">
      <div class="container">
          <div class="col-md-12">
            <h2>{title}</h2>
            <p>{text}</p>
          </div>
          {projects.map((proj) => (
              <div class="col-md-12">
                <div class="project">
                    <h4>{proj.title}</h4>
                    {proj.description && <p>{documentToReactComponents(proj.description.json)}</p>}
                    <div class="row">
                      {proj.images.map(({ image: { file: { url } }, caption }) => {
                        const content = (
                          <>
                            <img src={url} alt={caption} class="img-responsive" />
                            <p>{caption}</p>
                          </>
                        )
                        return (
                          <figure class='projectImage col-md-4 col-xs-6' onClick={() => openHandler(content)}>
                            <img src={url} alt={caption} class="img-responsive" />
                            <figcaption>{caption}</figcaption>
                          </figure>
                        )
                      })}
                    </div>
                </div>
              </div>
            )
          )}
          <Modal isOpen={modalOpen} onClose={closeHandler}>
            {modalContent}
          </Modal>
      </div>
    </div>         
  )
}

export default Projects
