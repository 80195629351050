import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { documentToReactComponents } from "@contentful/rich-text-react-renderer"
const About = ({ children }) => {
  const data = useStaticQuery(graphql`
    query AboutText {
      allContentfulAbout {
        nodes {
          title
          text {
            json
          }
        }
      }
    }
  `)
  const { title, text} = data.allContentfulAbout.nodes[0]
  return (
    <div id="about" class="section text-center landing-section">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-md-offset-1">
            <h2>{title}</h2>
            <h5>{documentToReactComponents(text.json)}</h5>
            <a href="#contact" class="btn btn-info btn-fill">Contact us</a>
          </div>
        </div>
      </div>
    </div>  
  )
}

export default About
